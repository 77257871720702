<template>
    <div v-auto-animate="{ duration: 150 }">
        <div v-if="errors" v-for="(item, key) in errors" v-auto-animate="{ duration: 150 }">
            <div v-if="!exclude.includes(key.toLowerCase())" class="alert mt-2" :class="{ 'alert-success': key == 'success', 'alert-danger': key != 'success'  }">{{ item }}</div>
        </div>
        <div class="alert alert-warning mt-2" v-if="capsLockOn">{{ $t('Caps Lock is on') }}</div>
    </div>
</template>

<script setup lang="ts">    
    const props = defineProps({ 
        errors: { type: Object, required: false, default: {} } ,
        capsLockOn: { type: Boolean, default: false },
        exclude: { type: Array, required: false, default: []}
    });
</script>